@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url("/assets/fonts/openSans/OpenSans-Light.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url("/assets/fonts/openSans/OpenSans-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/openSans/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url("/assets/fonts/openSans/OpenSans-Italic.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/openSans/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url("/assets/fonts/openSans/OpenSans-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/openSans/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url("/assets/fonts/openSans/OpenSans-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url("/assets/fonts/openSans/OpenSans-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url("/assets/fonts/openSans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
}
