*, *:before, *:after{
    box-sizing: border-box;

    /* Disabled the blue highlight at focus on mobile when element have cursor pointer */
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code,
em, img, small, strike, sub, sup, tt, u, i, ol, ul, li, fieldset, form, label, table,
caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

html {
    font-size: 62.5%;
    color: var(--color-white);
}

html, body { height: 100%; }

footer, header, nav, section, main{
    display: block;
}

body {
    background-color: #f0f3f6;
}

body, input, textarea, button {
    line-height: 1;
    font-family: var(--font-main);
    font-size: 1.5rem;
}

ol, ul{
    list-style: none;
}

blockquote, q{
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
    content: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

input, textarea {
    -webkit-appearance: none;
    border-radius: 0;

    &:focus {
        outline: none;
    }
}

button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;

    &:focus {
        outline: none;
    }
}

a {
    cursor: pointer;
    text-decoration: none;
    color: var(--color-white);

    &:focus {
        outline: none;
    }

    &:hover {
        color: var(--color-white);
    }
}

img {
    max-width: 100%;
}

small {
    font-size: 1.5rem;
}

b {
    font-weight: 800;
}


h2 {
    font-size: 3rem;
    font-weight: 800;
}

h3 {
    font-size: 2.5rem;
}
