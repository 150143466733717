:root {
    --color-black: #222b45;
    --color-white: #ffffff;
    --color-grey: #f0f0f0;

    --color-blue: #03318C;
    --color-medium-blue: #022B61;
    --color-dark-blue: #011C40;

    --color-green: #198754;

    --font-main: 'Open Sans', Arial, sans-serif;

    --header-height: 56px;

    --breakpoints: (
        small: 680px,
        medium: 950px,
        large: 1450px
    );
}
